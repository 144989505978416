import { LoginService } from 'projects/services/src/app/API/login/login.service';
import { ShareDataService } from 'projects/shared/src/app/shared/share-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoutingService } from 'projects/services/src/app/routing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'projects/services/src/app/storage.service';
import { ApiService } from 'projects/services/src/app/API/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrentUserService } from 'projects/services/src/app/current-user.service';
import { LoadingService } from 'projects/services/src/app/loading.service';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie';
// tslint:disable-next-line: import-blacklist
import { interval, Observable } from 'rxjs';
import { RegistrationService } from 'projects/services/src/app/API/registration.service';
import { ERROR_RESPONSE_MESSAGE } from 'projects/training-partner/src/app/training-partner/constants/string.constant';
import { ValidationService } from 'projects/services/src/app/validation.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';

declare var toastr: any;
declare var $: any;
declare var swal: any;
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
	userCredentials: { userName: string, password: string } = { userName: '', password: '' };
	loginForm: FormGroup;
	otpForm: FormGroup;
	contactDetailForm: FormGroup;
	show: boolean;
	idle: any;
	idleTimer: number = 0;
	commonToken = '';
	verifyUpdateDetails = false;
	userContactDetails: any;
	isUserAuthenticated = false;
	token;
	spoc;
	isNoContinueLoginFlag = false;
	pageLoad = false;

	constructor(
		private apiService: ApiService,
		private storageService: StorageService,
		private router: Router,
		private routingService: RoutingService,
		private formBuilder: FormBuilder,
		private currentUserService: CurrentUserService,
		private loadingService: LoadingService,
		private loginService: LoginService,
		private sharedService: ShareDataService,
		private jwtHelper: JwtHelperService,
		private userIdle: UserIdleService,
		private cookiService: CookieService,
		private registrationService: RegistrationService,
		private activatedRoute: ActivatedRoute
	) {
		this.loginForm = this.formBuilder.group({
			'username': ['', Validators.required],
			'password': ['', Validators.required],
			// 'recaptchaReactive': [null, Validators.required]
		});
		this.otpForm = this.formBuilder.group({
			'emailOTP': ['', [Validators.pattern('[0-9]{6}')]],
			'mobileOTP': ['', [Validators.pattern('[0-9]{6}')]]
		});
		this.contactDetailForm = this.formBuilder.group({
			'email': ['', [Validators.required, ValidationService.emailV]],
			'phone': ['', Validators.compose([Validators.required,
			Validators.pattern('^[4-9][0-9]{9}$')])],
		});
		this.show = false;
		console.log("login Page");
	}

	ngOnInit() {
		// Removed checking either user is already loggedin or  not ->   16/04/2017
		// this.checkForLoggedInUser();
		this.logoutExistingUser();
		// Helpers.openModal('m_modal_1_2');
		this.idleTimer = 0;
		this.isSidSipRedirection();
	}


	bufferToBase64(buffer: ArrayBuffer): string {
		const bytes = new Uint8Array(buffer);
		let binary = '';
		bytes.forEach((b) => (binary += String.fromCharCode(b)));
		return window.btoa(binary);
	}
	isSidSipRedirection() {
		// let route =  this.router.url.split('?')[0] 
		// if(route === '/sid') {
		this.activatedRoute.queryParams.subscribe(params => {
			let cred = params['uc'];
			if (cred) {
				this.pageLoad = true;
				let decryptedData = CryptoJS.AES.decrypt(cred, '0n3v1ew').toString(CryptoJS.enc.Utf8);
				decryptedData = JSON.parse(decryptedData);
				const date = new Date(decryptedData.Date);
				const urlTime = decryptedData.TimeStamp ? decryptedData.TimeStamp : new Date(date.setMinutes(date.getMinutes() + 1)).getTime();
				this.loginService.getSIDHServerTime().subscribe(sidh => {
					const currentTime = sidh.Data && sidh.Data.TimeStamp ? sidh.Data.TimeStamp : new Date().getTime();
					if (currentTime < urlTime) {
						if (decryptedData && decryptedData.UserName && decryptedData.Secret) {
							// call login api 
							let payload = {
								userName: decryptedData.UserName,
								secretkey: decryptedData.Secret,
								token: decryptedData.Token
							}

							this.loginService.login(payload).subscribe(
								res => {
									if (res) {
										let token = res.token || res.body && res.body.token;
										if (!token) {
										} else {
											this.sharedService.setData(null)
											this.storageService.setObject('access_token', token);
											this.routingService.goNext();
											//this.initiateIdleTimer()
										}
									}
								},
								err => {
									this.loadingService.unblockuI();
									toastr.error(err.error);
								}
							);
						} else {
							this.pageLoad = false;
						}
					} else {
						toastr.error('URL has been expired');
					}
				})
			} else {
				this.pageLoad = false;
			}
		});
	}

	checkForLoggedInUser() {
		if (this.storageService.getObject('access_token')) {
			this.currentUserService.getCurrentUser().subscribe((currentUser) => {
				if (currentUser != null) {
					this.routingService.goNext();
				} else {
					this.currentUserService.setCurrentUser();
					this.routingService.goNext();
				}
			});
		}
	}
	async doLogin() {
		// this.loadingService.blockUI();
		const loginUrl = environment.userServiceUrl + '/api/user/v1/login';
		const creds = Object.assign({}, this.userCredentials);
		// creds.userName = creds.userName.toUpperCase();
		const publicKey = await this.loginService.getPublicKey().toPromise();
		console.log(publicKey.publicKey);
		const encrypted = await this.loginService.encryptPassword(publicKey.publicKey, creds.password, publicKey.secret);
		creds.password = encrypted;
		creds.userName = creds.userName;
		// creds.password = btoa(creds.password);
		this.loginService.login(creds)
			.subscribe(
				res => {
					if (res) {
						this.token = res.token || res.body && res.body.token;
						this.commonToken = res.token || res.body && res.body.token;
						this.isUserAuthenticated = res.isUserAuthenticated;
						if (!this.token) {
						} else {
							if (res.isDuplicateSPOC) {
								this.storageService.setObject('access_token', this.token);
								this.sharedService.setData(this.userCredentials.userName);
								this.router.navigate(['/duplicate-spoc-change']);
								return;
							}
							if (res.hasOwnProperty('defaultPassword')) {
								this.storageService.setObject('access_token', this.token);
								this.sharedService.setData(this.userCredentials.userName);
								this.router.navigate(['/password-reset']);
							} else {
								// this.storageService.setObject('access_token', token);
								this.sharedService.setData('');
								const userDetails = this.jwtHelper.decodeToken(this.token);
								this.userContactDetails = res;
								if ((res.isUserAuthenticated === false || res.isUserAuthenticated === true) && (userDetails.role == 'NSDC Admin' || userDetails.role == 'Training Partner' ||
									userDetails.role == "NSDCAdmin" || userDetails.role == "NSDC IT ADMIN" || userDetails.role == "NSDC Super Admin" || userDetails.role == 'SSC')) {
									if (res.isUserAuthenticated == false) {
										Helpers.openModal('otpVerificationFirstTime');
										this.otpForm.controls.emailOTP.setValidators([Validators.required]);
										this.otpForm.controls.mobileOTP.setValidators([Validators.required]);
										this.otpForm.controls.emailOTP.updateValueAndValidity();
										this.otpForm.controls.mobileOTP.updateValueAndValidity();
									} else {
										this.generateOTPs();
										this.otpForm.controls.emailOTP.clearValidators();
										this.otpForm.controls.mobileOTP.clearValidators();
										this.otpForm.controls.emailOTP.updateValueAndValidity();
										this.otpForm.controls.mobileOTP.updateValueAndValidity();
										Helpers.openModal('otpVerification');
									}
								} else {
									if (res.passwordChangeRequired) {
										//set is password reset required to service and navigate to page to reset
										this.sharedService.setData('password-change');
										this.storageService.setObject('access_token', this.token);
										this.routingService.goNext();
										this.initiateIdleTimer()
										return;
									} else if (res.spocChangeRequired) {
										this.spoc = {
											phone: res.phone || '',
											email: res.email || ''
										}
										Helpers.openModal('spoc-confirmation');
										this.initiateIdleTimer();
										return;
									} else {
										this.sharedService.setData(null)
										this.storageService.setObject('access_token', this.token);
										this.routingService.goNext();
										this.initiateIdleTimer()
									}

								}

							}
						}

					}
				},
				err => {
					this.loadingService.unblockuI();
					if (err.error instanceof HttpErrorResponse) {
						toastr.error(err.error);
					}
				}
			);

	}

	initiateIdleTimer() {
		// Start watching for user inactivity.\
		let counter = 5;
		this.userIdle.startWatching();
		// Start watching when user idle is starting and reset if user action is there.
		this.userIdle.onTimerStart().subscribe(count => {
			// counter = (count - 300)
			if (count === 1) {
				swal({
					title: `Your session will be expiring in <b>${counter} Minutes</b>, do you want to continue?`,
					type: 'warning',
					showCancelButton: true,
					cancelButtonText: 'No',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					confirmButtonColor: '#006400'
				}).then((action: any) => {
					if (action.value) {
						this.getRefreshtoken();
						this.userIdle.stopTimer();
					} else {
						this.logout()
					}
				})
			}
			const eventList = [
				'mousedown',
				'click',
				'keydown',
				'keyup'
			];
			for (let event of eventList) {
				document.body.addEventListener(event, () => this.userIdle.resetTimer());
			}
		});
		// Start watch when time is up.
		this.userIdle.onTimeout().subscribe((res) => {
			if (res) {
				swal.clickCancel();
				swal.close();
			}
		})

		interval(900000).subscribe(x => {
			const token = this.storageService.getObject('access_token');
			if (token) {
				this.currentUserService.getRefreshToken().subscribe(r => {
					console.log(r);
					if (r && r.authToken) {
						// this.cookiService.remove('_usr');
						this.storageService.setObject('access_token', token);
						localStorage.setItem('access_token', r.authToken);
						// this.cookiService.put('_usr', r.authToken);
					}
				})
			}
		});
	}

	logout() {
		const token = this.storageService.getObject('access_token');
		if (token) {
			const userDetails = this.jwtHelper.decodeToken(token);
			if (this.jwtHelper.isTokenExpired(token) === false) {
				this.storageService.removeAll();
				const cred = {
					userName: userDetails.userName
				};
				this.loginService.logout(cred, true).subscribe(res => {
				});
				this.userIdle.stopWatching();
				// this.userIdle.stopTimer();
				this.router.navigate(['/']);
				// localStorage.clear();
			} else {
				const cred = {
					userName: userDetails.userName
				};
				this.loginService.logout(cred, true).subscribe(res => {
				});
				this.storageService.removeAll();
				this.userIdle.stopWatching();
				// this.userIdle.stopTimer();
				localStorage.clear();
				// this.cookiService.remove('_usr');
				this.router.navigate(['/']);
			}
		}
	}

	getRefreshtoken() {
		const token = this.storageService.getObject('access_token');
		if (token) {
			this.currentUserService.getRefreshToken().subscribe(r => {
				console.log(r);
				if (r && r.authToken) {
					localStorage.setItem('access_token', r.authToken);
					this.storageService.setObject('access_token', token);
					// this.cookiService.remove('_usr');
					// this.cookiService.put('_usr', r.authToken);
					this.userIdle.resetTimer();
				}
			})
		}
	}


	showPassClick() {
		this.show = !this.show;

	}
	logoutExistingUser() {
		const token = this.storageService.getObject('access_token');
		if (token) {
			const userDetails = this.jwtHelper.decodeToken(token);
			if (this.jwtHelper.isTokenExpired(token) === false) {
				this.storageService.removeAll();
				const cred = {
					userName: userDetails.userName
				};
				this.loginService.logout(cred, true).subscribe(res => { });
			}
		}

	}

	ngOnDestroy() {
		Helpers.closeModal('m_modal_1_2');
	}

	generateOTPs() {
		const creds = this.userCredentials.userName;
		this.registrationService.generateOTPForForgotPassword(creds, true)
			.subscribe(
				res => {
					this.userContactDetails = res;
				}, err => {
					if (err && err.error && err.error.message) {
						toastr.error(err.error.message);
					}
				});
	}

	verifyOTP(m?) {
		let setPasswordDetails = {}
		if (m === 'everyTime') {
			setPasswordDetails = {
				phoneOtp: this.otpForm.get('emailOTP').value,
				emailOtp: this.otpForm.get('emailOTP').value,
				userID: this.userCredentials.userName,
			};
		} else {
			setPasswordDetails = {
				phoneOtp: this.otpForm.get('mobileOTP').value,
				emailOtp: this.otpForm.get('emailOTP').value,
				userID: this.userCredentials.userName,
			};
		}

		if (this.isNoContinueLoginFlag) {
			this.registrationService.verifyOTPForTPTCSpoc(setPasswordDetails, true).subscribe(res => {
				if (res) {
					swal('OTP is verified Successfully')
						.then((action: any) => {
							if (action.value) {
								this.loginService.updateSpocFlag().subscribe(res => { });
								this.storageService.setObject('access_token', this.commonToken);
								this.routingService.goNext();
								this.initiateIdleTimer();
							} else {
								this.logout()
							}
						});
					Helpers.closeModal('otpVerification');
					Helpers.closeModal('otpVerificationFirstTime');
					this.otpForm.reset();
					// this.storageService.setObject('access_token', this.commonToken);
					// this.routingService.goNext();
					// this.initiateIdleTimer();
					// this.showform = 'password';
				}
			}, err => {
				if (err && err.error && err.error.message) {
					toastr.error(atob(err.error.message));
					if (atob(err.error.message).trim() === ERROR_RESPONSE_MESSAGE.INVALID_OTP_5_TIMES) {
						// this.showform = 'generateOTP';
					}
				}
			})
		} else {
			this.registrationService.verifyOTPForRegistration(setPasswordDetails, true).subscribe(res => {
				if (res) {
					swal('OTP is verified Successfully')
						.then((action: any) => {
							if (action.value) {
								this.loginService.updateSpocFlag().subscribe(res => { });
								this.storageService.setObject('access_token', this.commonToken);
								this.routingService.goNext();
								this.initiateIdleTimer();
							} else {
								this.logout()
							}
						});
					Helpers.closeModal('otpVerification');
					Helpers.closeModal('otpVerificationFirstTime');
					this.otpForm.reset();
					// this.storageService.setObject('access_token', this.commonToken);
					// this.routingService.goNext();
					// this.initiateIdleTimer();
					// this.showform = 'password';
				}
			}, err => {
				if (err && err.error && err.error.message) {
					toastr.error(atob(err.error.message));
					if (atob(err.error.message).trim() === ERROR_RESPONSE_MESSAGE.INVALID_OTP_5_TIMES) {
						// this.showform = 'generateOTP';
					}
				}
			})
		}
	}
	get validOTP() {
		if ((!!this.otpForm.get('mobileOTP').value || !!this.otpForm.get('emailOTP').value)) {
			if (!!this.otpForm.get('mobileOTP').value) {
				return !!this.otpForm.get('mobileOTP').valid;
			} else {
				return !!this.otpForm.get('emailOTP').valid;
			}
		}
	}

	get validContactDetail() {
		if ((!!this.contactDetailForm.get('phone').value || !!this.contactDetailForm.get('email').value)) {
			if (!!this.contactDetailForm.get('phone').value) {
				return !!this.contactDetailForm.get('phone').valid;
			} else {
				return !!this.contactDetailForm.get('email').valid;
			}
		}
	}

	updateContactDetails() {
		const setContactDetails = {
			phone: this.contactDetailForm.get('phone').value,
			email: this.contactDetailForm.get('email').value,
			userName: this.userCredentials.userName,
		};

		this.registrationService.updateUserContactInfo(setContactDetails).subscribe(res => {
			if (res) {
				this.userContactDetails = res;
				this.contactDetailForm.reset();
				this.verifyUpdateDetails = true;
				// this.showform = 'password';
			}
		}, err => {
			if (err && err.error && err.error.message) {
				console.log("err message : ", err.error.message)
				// toastr.error(err.error.message);
				// return
				// if (atob(err.error.message).trim() === ERROR_RESPONSE_MESSAGE.INVALID_OTP_5_TIMES) {
				// 	// this.showform = 'generateOTP';
				// }
			}
		})
	}
	resetModel() {
		this.contactDetailForm.reset();
		this.verifyUpdateDetails = false;
		this.otpForm.reset();
		Helpers.closeModal('otpVerificationFirstTime');
		Helpers.closeModal('otpVerification');
		this.otpForm.controls.emailOTP.clearValidators();
		this.otpForm.controls.mobileOTP.clearValidators();
		this.otpForm.controls.emailOTP.updateValueAndValidity();
		this.otpForm.controls.mobileOTP.updateValueAndValidity();
	}
	spocChange() {
		this.isNoContinueLoginFlag = false;
		Helpers.closeModal('spoc-confirmation')
		this.sharedService.setData('spoc-change');
		this.storageService.setObject('access_token', this.token);
		this.routingService.goNext();
		return;
	}

	ContinueForOtpVerification() {
		this.isNoContinueLoginFlag = true;
		Helpers.closeModal('spoc-confirmation');
		this.generateOTPs();
		this.otpForm.controls.emailOTP.clearValidators();
		this.otpForm.controls.mobileOTP.clearValidators();
		this.otpForm.controls.emailOTP.updateValueAndValidity();
		this.otpForm.controls.mobileOTP.updateValueAndValidity();
		this.verifyUpdateDetails = true;
		Helpers.openModal('otpVerificationFirstTime');
	}

	continueDashboard() {
		Helpers.closeModal('spoc-confirmation');
		Helpers.closeModal('otpVerificationFirstTime');
		this.loginService.updateSpocFlag().subscribe(res => { });
		this.storageService.setObject('access_token', this.token);
		this.routingService.goNext();
	}

}
