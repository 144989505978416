import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SchemeService } from "../API/scheme.service";
import { Helpers } from "projects/training-calendar/src/app/helpers";
import * as moment from "moment";
import { LoadingService } from "../loading.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
declare const swal: any;
@Injectable({ providedIn: 'root' })
export class SharedService {
	rplIV = false;
	backClick = false;
	allRequests: any = [];
	multiqpData: any;
	docketData: any;
	validationMessage: any;
	data: any = '';

	constructor(
		private schemeService: SchemeService,
		private loaderService: LoadingService
	) { }
	subject: Subject<any> = new Subject();
	profilePhoto: BehaviorSubject<any> = new BehaviorSubject(null);
	dynamicErroeEventFired: BehaviorSubject<any> = new BehaviorSubject(false);
	isAadhaarValid: Subject<boolean> = new Subject();
	isVirtualValid: Subject<boolean> = new Subject();

	// In Create SSC for search TC functionality
	state: Subject<any> = new Subject();
	district: Subject<any> = new Subject();
	trainingCentreId: Subject<any> = new Subject();
	trainingCentreType: Subject<any> = new Subject();
	selState: Subject<any> = new Subject();
	selDistrict: Subject<any> = new Subject();
	search: Subject<any> = new Subject();
	reloadBatch: Subject<any> = new Subject();
	resetTCSearch: Subject<any> = new Subject();

	// In Create batch SSC for search MT
	qpCodeForMT: Subject<any> = new Subject();
	qpCodeForAA: Subject<any> = new Subject();
	batchId: Subject<any> = new Subject();
	qpAcronymousData = new Subject<any>();
	qpAcronymousDataSave$ = this.qpAcronymousData.asObservable();
	nosAcronymousData = new Subject<any>();
	nosAcronymousDataSave$ = this.nosAcronymousData.asObservable();
	// qpCodeForMT: Subject<any> = new Subject()
	// qpCodeForAA: Subject<any> = new Subject()
	// batchId: Subject<any> = new Subject()
	// allRequests: any[] = [];

	// In master trainer attendance
	submitToSSC: Subject<any> = new Subject(); // observalble for submit button in attendance upload page

	// For SSC Profile View profile info based on CEO or chairman
	roleBasedOnChairmanOrSSC: Subject<string> = new Subject();
	saveAsDraft: Subject<any> = new Subject();
	saveAsDraftQp: Subject<any> = new Subject();
	emitBackEvent: Subject<any> = new Subject();
	saveAsDraftNos: Subject<any> = new Subject();

	// roleBasedOnChairmanOrSSC: Subject<string> = new Subject()
	reassessmentToBeCompletedDays: any;

	public certificationButtonNotify = new BehaviorSubject<any>('');

	certificationButtonNotifyObservable = this.certificationButtonNotify.asObservable();

	public notifyApplicantCertificationStatus(data: any) {
		if (data) {
			this.certificationButtonNotify.next(data);
		}
	}

	setData(value) {
		this.data = value
	}
	getData() {
		return this.data;
	}
	setRplIV(value) {
		this.rplIV = value;
	}
	getRplIV() {
		return this.rplIV;
	}
	setProfilePhoto(file) {
		this.profilePhoto.next(file);
	}

	getMessage(): Observable<any> {
		return this.saveAsDraft.asObservable();
	}

	getProfilePhoto(): Observable<any> {
		return this.profilePhoto.asObservable();
	}

	setdynamicErroeEventFired(value) {
		this.dynamicErroeEventFired.next(value);
	}

	getdynamicErroeEventFired(): Observable<any> {
		return this.dynamicErroeEventFired.asObservable();
	}

	clearValidators(formGroup: FormGroup, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(control).setValidators(null);
				formGroup.get(control).clearValidators();
				formGroup.get(control).updateValueAndValidity();
				formGroup.get(control).markAsTouched();
			});
		} else {
			formGroup.get(controls).setValidators(null);
			formGroup.get(controls).clearValidators();
			formGroup.get(controls).updateValueAndValidity();
			formGroup.get(controls).markAsTouched();
		}
	}

	clearValidationAndValues(formGroup: FormGroup, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(control).setValidators([]);
				formGroup.get(control).clearValidators();
				formGroup.get(controls).setValue(null);
				formGroup.get(control).updateValueAndValidity();
				formGroup.get(control).markAsTouched();
			});
		} else {
			formGroup.get(controls).setValidators([]);
			formGroup.get(controls).clearValidators();
			formGroup.get(controls).setValue(null);
			formGroup.get(controls).updateValueAndValidity();
			formGroup.get(controls).markAsTouched();
		}
	}

	multiStepclearValidators(formGroup: FormGroup, fromgroupName, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				// formGroup.get(fromgroupName).get(control).setValidators(null);
				formGroup.get(fromgroupName).get(control).clearValidators();
				formGroup
					.get(fromgroupName)
					.get(control)
					.updateValueAndValidity();
				formGroup.get(fromgroupName).get(control).markAsTouched();
			});
		} else {
			// formGroup.get(fromgroupName).get(controls).setValidators(null);
			formGroup.get(fromgroupName).get(controls).clearValidators();
			formGroup.get(fromgroupName).get(controls).updateValueAndValidity();
			formGroup.get(fromgroupName).get(controls).markAsTouched();
		}
	}

	setDisable(formGroup: FormGroup, controls) {
		formGroup.get(controls).disable();
		formGroup.get(controls).updateValueAndValidity();
	}

	setEnable(formGroup: FormGroup, controls) {
		formGroup.get(controls).enable();
		formGroup.get(controls).updateValueAndValidity();
	}

	multiStepDisable(formGroup: FormGroup, fromgroupName, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(fromgroupName).get(control).disable();
				formGroup.get(fromgroupName).get(control).updateValueAndValidity();
			});
		} else {
			formGroup.get(fromgroupName).get(controls).disable();
			formGroup.get(fromgroupName).get(controls).updateValueAndValidity();
		}
	}

	multiStepClearFormValues(formGroup: FormGroup, fromgroupName, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(fromgroupName).get(control).setValue("");
				formGroup.get(fromgroupName).get(control).updateValueAndValidity();
			});
		} else {
			formGroup.get(fromgroupName).get(controls).setValue("");
			formGroup.get(fromgroupName).get(controls).updateValueAndValidity();
		}
	}

	multiStepEnable(formGroup: FormGroup, fromgroupName, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(fromgroupName).get(control).enable();
				formGroup.get(fromgroupName).get(control).updateValueAndValidity();
			});
		} else {
			formGroup.get(fromgroupName).get(controls).enable();
			formGroup.get(fromgroupName).get(controls).updateValueAndValidity();
		}
	}

	setValidators(formGroup: FormGroup, controls, validation) {
		formGroup.get(controls).setValidators(validation);
		formGroup.get(controls).updateValueAndValidity();
		formGroup.get(controls).markAsTouched();
	}

	multiStepSetValidators(
		formGroup: FormGroup,
		fromgroupName,
		controls,
		validation
	) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(fromgroupName).get(control).setValidators(validation);
				formGroup.get(fromgroupName).get(control).updateValueAndValidity();
			})
		} else {
			formGroup.get(fromgroupName).get(controls).setValidators(validation);
			formGroup.get(fromgroupName).get(controls).updateValueAndValidity();
			formGroup.get(fromgroupName).get(controls).markAsTouched();
		}
	}

	setErrors(formGroup: FormGroup, controls, validationMessage) {
		formGroup.get(controls).setErrors({
			serverError: validationMessage,
		});
		formGroup.get(controls).markAsTouched();
	}

	multiStepSetErrors(
		formGroup: FormGroup,
		fromgroupName,
		controls,
		validationMessage
	) {
		formGroup.get(fromgroupName).get(controls).setErrors({
			serverError: validationMessage,
		});
		formGroup.get(fromgroupName).get(controls).updateValueAndValidity();
		formGroup.get(fromgroupName).get(controls).markAsTouched();
	}

	// public markFormGroupTouched(formGroup: FormGroup) {
	// 	Object.values(formGroup.controls).forEach(control => {
	// 		control.markAsTouched();
	// 		if (control['controls']) {
	// 			control['controls'].forEach(c => this.markFormGroupTouched(c));
	// 		}
	// 	});
	// 	window.scrollTo(0, 0);
	// }

	resetControls(formGroup: FormGroup, controls) {
		if (typeof controls === "object") {
			controls.map((control) => {
				formGroup.get(control).reset();
			});
		} else {
			formGroup.get(controls).reset();
		}
	}

	mobileValidation(evt) {
		evt = evt ? evt : window.event;
		const charCode = evt.which ? evt.which : evt.keyCode;
		if (
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			evt.key !== "Backspace"
		) {
			return false;
		}
		if (evt.target.value.length > 9 && evt.key !== "Backspace") {
			return false;
		}
		return true;
	}

	onlyNumberPer(evt) {
		let val1;
		const charCode = evt.which ? evt.which : evt.keyCode;
		if ([0, 8].indexOf(charCode) !== -1) {
			return;
		}
		const parts = evt.srcElement.value.split(".");
		if (parts.length > 2) {
			return false;
		}
		if (evt.keyCode === 46) {
			return parts.length === 1;
		}
		if (evt.keyCode !== 46) {
			const currVal = String.fromCharCode(evt.keyCode);
			val1 = parseFloat(String(parts[0]) + String(currVal));
			if (parts.length === 2) {
				val1 = parseFloat(String(parts[0]) + `.` + String(currVal));
			}
		}
		if (val1 > 100) {
			return false;
		}
		if (parts.length === 2 && parts[1].length >= 2) {
			return false;
		}
	}

	public multilevelmarkFormGroupTouched(control) {
		Object.keys(control).forEach((rescontrol) => {
			control[rescontrol].markAsTouched();
		});
	}

	public markFormGroupTouched(formGroup: FormGroup) {
		// window.scrollTo(0, 0);
		(<any>Object).values(formGroup.controls).forEach((control) => {
			if (control.controls) {
				this.markFormGroupTouched(control);
			} else {
				control.markAsTouched();
			}
		});
	}

	centreAreaChange(data, dateComing) {
		if (data !== 0) {
			const date = new Date(dateComing);
			const startDate = moment(date, "DD-MM-YYYY");
			const endDate = moment(
				`31-03-${date.getFullYear() + 1}`,
				"DD-MM-YYYY"
			);
			let actualDays = endDate.diff(startDate, "days") + 1;
			actualDays = (actualDays * 72) / 100;
			let capacity = data;
			if (capacity && capacity >= 1000) {
				capacity = capacity - (capacity * 20) / 100;
			}
			capacity = (capacity * 2) / 10;
			capacity = Math.round((capacity * actualDays) / 90);
			return capacity;
		}
	}

	centreAreaChangeUsability(data, dateComing, trainedCandidates) {
		if (data !== 0) {
			const date = new Date(dateComing);
			const startDate = moment(date, "DD-MM-YYYY");
			const endDate = moment(
				`31-03-${date.getFullYear() + 1}`,
				"DD-MM-YYYY"
			);
			let actualDays = endDate.diff(startDate, "days") + 1;
			actualDays = (actualDays * 72) / 100;
			let capacity = data;
			if (capacity && capacity >= 1000) {
				capacity = capacity - (capacity * 20) / 100;
			}
			capacity = (capacity * 2) / 10;
			capacity = Math.round((capacity * actualDays) / 90);
			// if (trainedCandidates && capacity) {
			capacity = `${trainedCandidates}/${capacity} (${Math.round(
				(trainedCandidates / capacity) * 100
			)}%)`;
			// }
			return capacity;
		} else {
			return `${0}/${0} (0%)`;
		}
	}

	assignAllrequests(url) {
		this.allRequests.push(url);
		this.loaderService.blockUI();
		// this.stopLoaderManully();
	}

	removeUrlOnresponse(url) {
		const index = this.allRequests.indexOf(url);
		if (index > -1) {
			this.allRequests.splice(index, 1);
		}

		if (this.allRequests.length === 0) {
			this.loaderService.unblockuI();
		}
	}

	checkForDuplicateUrl(url) {
		const index = this.allRequests.indexOf(url);
		if (index > -1) {
			return false;
		} else {
			return true;
		}
	}

	// stopLoaderManully() {
	// 	setTimeout(() => {
	// 		this.loaderService.unblockuI();
	// 	}, 8000)
	// }

	// multiqp-attach-docs
	setMultiQpData(data) {
		console.log(data);
		this.multiqpData = data;
	}

	getMultiQpData() {
		return this.multiqpData;
	}

	setMultiQpDocketData(data) {
		console.log(data);
		this.docketData = data.Docket;
	}

	getDocketData() {
		return this.docketData;
	}
	// end

	setValidation(data) {
		console.log(data);
		this.validationMessage = data;
	}

	getValidation() {
		return this.validationMessage;
	}
	async reassessmentBlockMessage(sscApprovedDate, batch): Promise<boolean> {
		const today = new Date();
		if (batch.schemeObjectId) {
			let getReassessmentdays = await this.getSchemeDetails(
				batch.schemeObjectId
			);
			const duration = Helpers.calculateDuration(today, sscApprovedDate);
			if (duration > getReassessmentdays) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	async getSchemeDetails(schemeId) {
		return new Promise((resolve, reject) => {
			try {
				// this.schemeService.getSchemeDetailsV2(schemeId).subscribe(res => {
				const schemeFieldsToProject = [
					"schemeDetails.assessmentDetails",
				];
				this.schemeService
					.getSingleSchemeDetailBasedOnScenario(
						schemeId,
						"projection",
						schemeFieldsToProject
					)
					.subscribe((res) => {
						console.log("isExceeded", res);
						if (
							res &&
							res.data &&
							res.data.schemeDetails &&
							res.data.schemeDetails.assessmentDetails &&
							res.data.schemeDetails.assessmentDetails.noOfDays &&
							res.data.schemeDetails.assessmentDetails.noOfDays
								.withinWhichReAssessmentToBeCompleted
						) {
							this.reassessmentToBeCompletedDays =
								res.data.schemeDetails.assessmentDetails.noOfDays.withinWhichReAssessmentToBeCompleted;
							resolve(this.reassessmentToBeCompletedDays);
						} else {
							resolve(undefined);
						}
					});
			} catch (e) {
				reject(e);
			}
		});
	}
}
